<template>
    <!--  :footerBtnName="['取消','同步']"-->
        <r-e-dialog title="押金转移余额申请" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                    :footerBtnName="['取消','提交']"  fullscreen
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <div style="height: 79vh;">
                <div style="height: 100%;width: 100%;">
                    <el-steps :active="0" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                        <el-step title="申请-管家申请">
                            <div slot="description">
                                <div>申请人: {{ submitUserName }}</div>
                            </div>
                        </el-step>
                        <el-step title="片管员预审"></el-step>
                        <el-step title="负责人审核"></el-step>
                        <el-step title="财务部审核"></el-step>
                        <el-step title="查看"></el-step>
                    </el-steps>
                    <div style="height: 90%;display: flex;flex-flow: column wrap;">
    
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="title">当前有效合同</div>
                        </div>
                        <div class="boder-right width33b padding_0_30" >
                            <!-- <div class="flex justify-between row bg-E2F5FF" style="margin-bottom:0;">
                                <div class="text-left width200" style="margin-right:10px">合同编号</div>
                                <div class="text-left width300" style="margin-right:10px">房源</div>
                                <div class="text-left width300">合同周期</div>
                            </div> -->
                            <!-- <div class="flex justify-between row " style="margin-bottom:0;border-top:0px;"  v-for="(item, index) in workOrderContractVoList"  :key="index">
                                <div class="text-left width200" style="margin-right:5px">{{item.contractNum}}</div>
                                <div class="text-left width300" style="margin-right:10px">{{item.apartmentName}}</div>
                                <div class="text-left width300">{{item.contractCycle}} </div>
                            </div> -->
                            <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLeasorTableData" :columns="tableColumn"
                             :height="400" :showPagination="true" >
    
                                <template slot="empty">
                                    <el-empty/>
                                </template>
                            </r-e-table>
    
    
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="title">租户信息</div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">{{ checkOurData.leasorName }}</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">申请时间    {{ transferDate }} </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="title">现有余额</div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">余额类型</div>
                                <div class="text-right width100">合计(元)</div>
                            </div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">押金余额</div>
                                <div class="text-right width100">{{depositBalanceTransferBeforeAmount}}</div>
                            </div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="title">转移余额</div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">余额类型</div>
                                <div class="text-right width100">合计(元)</div>
                            </div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100"><el-link type="primary">房租余额</el-link></div>
                                <div class="text-right width100">
                                    <span v-if="!rentalShow" @click="amountClick('rentalShow', 'transferRentalBalanceAmount')" style="color:rgb(64, 158, 255)">
                                        {{ checkOurData.transferRentalBalanceAmount }}    
                                    </span>
                                    <el-input v-else v-model="checkOurData.transferRentalBalanceAmount" ref="transferRentalBalanceAmount" type="number"  color="red" size="mini"
                                              @blur="amountBlur('rentalShow', 'transferRentalBalanceAmount')" placeholder="请输入内容"/>
                                </div>
                                <!-- <div class="text-right width100"><el-link type="primary">100.00</el-link></div> -->
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100"><el-link type="primary">杂费余额</el-link></div>
                                <div class="text-right width100">
                                    <span v-if="!otherShow" @click="amountClick('otherShow', 'transferOtherBalanceAmount')" style="color:rgb(64, 158, 255)">
                                        {{ checkOurData.transferOtherBalanceAmount }}
                                    </span>
                                    <el-input v-else v-model="checkOurData.transferOtherBalanceAmount" ref="transferOtherBalanceAmount" type="number" size="mini"
                                              @blur="amountBlur('otherShow', 'transferOtherBalanceAmount')" placeholder="请输入内容"/>
                                </div>
                            </div>
                        </div>
                        <div class="flex align-baseline width33b padding_10_30 fw600" style="color:red">
                            <div style="width: 50px;">总计:</div>
                            <div>
                                <div style="font-size: 24px;">{{ totalAmount }}</div>
                                <div  style="font-size: 12px;">含房租余额{{ checkOurData.transferRentalBalanceAmount }},杂费余额{{ checkOurData.transferOtherBalanceAmount }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="title"> 剩余余额</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">余额类型</div>
                                <div class="text-right width100">合计(元)</div>
                            </div>
                        </div>
    
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">押金余额</div>
                                <div class="text-right width100">{{depositBalanceTransferAfterAmount}}</div>
                            </div>
                        </div>
    
    
    
                        <div style="width: 50%;padding: 10px 30px;">
                            <div style="font-weight: 600;padding-bottom: 10px">备注</div>
                            <el-input type="textarea" v-model="checkOurData.remark" :rows="3" style="width: 100%;"
                                      placeholder="请输入内容" />
                        </div>
    
                        <!-- <div class="width33b padding_10_30">
                            <el-badge :value="checkOutDisposeLog.length">
                                <el-button type="primary" @click="handleRecordSwitch=true">处理记录</el-button>
                            </el-badge>
                        </div> -->
                    </div>
                </div>
            </div>
    
            <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px"
                        :showFooter="false"  v-dialog-drag>
                <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                           show-summary :summary-method="getSummaries">
                    <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
                    <!--<el-table-column slot="toolbar" label="操作" width="100">
                        <template slot-scope="{ row }">
                            <div class="table-tools">
                                <span class="table-btn" @click="update(row)">修改</span>
                            </div>
                        </template>
                    </el-table-column>-->
                </r-e-table>
            </r-e-dialog>
            <!-- <el-button  slot="footer"  type="danger" @click="saveWorkOrder">作废</el-button>
            <el-button v-if="state!==0" slot="footer" type="info" ref="bh" @click="rejectFun">驳回</el-button> -->
            <el-button  slot="footer" class="submit" @click="saveWorkOrder">保存</el-button>
        </r-e-dialog>
    </template>
    
    <script>
    import {MessageInfo, MessageSuccess} from "@custom/message";
    import {timeFormat, numberFormat} from "@custom/index";
    import {
        getWordInfo, wordDispose, wordAudit, checkoutAuditRejectionApi,newobjgongdanApi,
        getTransferWorkOrder,getSubmitTransferWorkOrder,getContractPageApi
    } from "@/api/work-order-management";
    import {contractCheckoutExit} from "@/api/contract";
    import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
    import {houseBillColumns} from "@/views/rental-management/house-resource/data";
    import {contracColumns} from "@/views/rental-management/tenant-management/data";
    import {getPaybillUuidsList,synchronizationapi} from "@/api/bill";
    // video-upload-perfect
    export default {
        name: "dialog-check-out-work-order-handle",
        data() {
    
            return {
                formSearch: {
                    leasorUuid: "",
    
                },
                workOrderContractVoList:[],//当前有效合同列表
                depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换后
                transferRentalBalanceAmount:null,// 转移余额-房租余额 转换后
                transferOtherBalanceAmount:null,// 转移余额-杂费余额 转换后
                totalAmount:null , //转移余额-总计 转换后
                depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换后
                tableColumn: contracColumns(this),
                disabledtype:false,
                deletetype:false,
                shanchu: true,
                dialogVisible: false,
                title: '',
                loading: false,
                paymentTypeList: [],
                imgList: [],
                checkOurDateShow: false,
                endDate: null,
                uuid: null,
                contractUuid:null,
                checkOurData: {
                    leasorName:null,// 租户名称
                    transferDate:null, //申请时间
                    depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换前
                    transferRentalBalanceAmount:null,// 转移余额-房租余额 转换前
                    transferOtherBalanceAmount:null , // 转移杂费-房租余额 转换前
                    depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换前
                    totalAmount:null, // 转移余额总计 转换前
                    remark:null , // 备注
                },
                rentalAmountShow: false,
                serviceAmountShow: false,
                rentalBalanceShow: false,
                expenseBalanceShow: false,
                noPaidRentalAmountShow: false,
                noPaidServiceAmountShow: false,
                noPaidWaterAmountShow: false,
                noPaidElectAmountShow: false,
                cleanShow: false,
                damageShow: false,
                otherShow: false, // 杂费余额是否可修改
                rentalShow: false, // 房租余额是否可修改
                state: null,
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                billTitle: "",
                billSwitch: false,
                houseBillColumns: houseBillColumns(this),
                billList: [],
                billUuids: null,
                active: 4,
                submitter: null,    //申请人
                submitDate: null,   //申请时间
                personChargeUserName: null, //片管员
                personChargeDate: null, //片管员操作时间
                confirmUser: null,  //租赁部
                confirmDate: null,  //租赁部操作时间
                handleUser: null,   //财务部
                handleDate: null,   //财务部操作时间
                rejectShow: false,
                rejectForm: {
                    reason: null,
                },
                rejectRules: {
                    reason: [{required: true, message: '请输入驳回原因', trigger: 'blur'}]
                },
                checkOutDisposeLog: [],
                handleRecordSwitch: false,
                handleRecordColumns: [
                    {prop: "name", label: "处理人", width: 100},
                    {prop: "operatingState", label: "操作", width: 100},
                    {prop: "remark", label: "驳回原因",},
                    {prop: "controlsDate", label: "时间", width: 150},
                ]
            };
        },
        props: {},
        methods: {
            async getLeasorTableData(params) {
                let leasorUuid = this.leasorUuid;
                 params.leasorUuid = leasorUuid;
                return getContractPageApi(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData(false);
            },
          // 上传视频事件
          handleUploadSuccess({info}) {
            // 上传视频
            this.videoUrl = info.uuid;
            this.checkOurData.videoUrl = info.uuid;
          },
           //2024/04/17 张晓瑜新增删除已上传的视频
           deleteUploadedVideo() {
            this.checkOurData.videoUrl = ''; // 清空视频 UUID
            console.log('this.formPublish.videoUrl0',this.checkOurData.videoUrl);
            // this.isUploaded = false; // 标记未上传视频
            },
            async openDialog(data) {
              console.log('data消息,',data)
                this.title = '处理';
                const {uuid, status, leasorUuid} = data;
                this.active = status;
                this.leasorUuid = uuid;
                this.formSearch.leasorUuid = uuid;
                this.state = status;
                await this.getTransferWork();
                await this.getLeasorTableData();
                this.handleSearch()
    
                // this.$nextTick(() => {
                //     if (this.state !== 0) {
                //         const bh = this.$refs['bh'].$el.parentNode.childNodes[2];
                //         const qx = this.$refs['bh'].$el.parentNode.childNodes[0];
                //         this.$refs['bh'].$el.parentNode.insertBefore(bh, qx);
                //     }
                // });
            },
            //获取发起申请时个人信息
           async getTransferWork(){
                 let leasorUuid = this.leasorUuid;
                 const loadingOptions = this.loadingOptions;
                 const loading = this.$loading({...loadingOptions});
                 getTransferWorkOrder({ leasorUuid}).then(res => {
                 console.log('res',res);
                 const {info} = res;
                 this.uuid = info.uuid; //合同uuid
                 this.workOrderContractVoList = info.workOrderContractVoList;  // 当前有效合同列表
                 this.checkOurData.leasorName = info.leasorName // 租户名称
                 this.checkOurData.depositBalanceTransferBeforeAmount = info.depositBalanceTransferBeforeAmount // 押金余额-现有余额
                 this.depositBalanceTransferBeforeAmount = parseFloat(this.checkOurData.depositBalanceTransferBeforeAmount  / 100).toFixed(2); // 押金余额-现有余额 转换后
                 this.checkOurData.transferRentalBalanceAmount = info.transferRentalBalanceAmount ;//转移余额-房租余额
                 this.transferRentalBalanceAmount = parseFloat(this.checkOurData.transferRentalBalanceAmount / 100).toFixed(2); // 转移余额-房租余额 转换后
                 this.checkOurData.transferOtherBalanceAmount = info.transferOtherBalanceAmount ;//转移余额-杂费余额
                 this.transferOtherBalanceAmount = parseFloat(this.checkOurData.transferOtherBalanceAmount / 100).toFixed(2); // 转移余额-杂费余额 转换后
                 this.checkOurData.depositBalanceTransferAfterAmount = info.depositBalanceTransferAfterAmount; //剩余余额-押金余额
                 this.depositBalanceTransferAfterAmount = parseFloat(this.checkOurData.depositBalanceTransferAfterAmount  / 100).toFixed(2); // 剩余余额-押金余额 转换后
                 this.checkOurData.totalAmount = info.totalAmount;
                 this.totalAmount = parseFloat(this.checkOurData.totalAmount   / 100).toFixed(2); // 转移金额-总计 转换后
                 this.submitUserName = info.submitUserName; 
                 this.checkOurData.transferDate = info.transferDate;
                 this.transferDate = info.transferDate ? timeFormat(new Date(info.transferDate), "yyyy-MM-dd") : '';
                 this.dialogVisible = true;
                }).finally(() => loading.close());
           },
           // 处理字符串函数，照片分割字符串
           splitAndFilter(workPictures, separator = ',') {
            if (typeof workPictures !== 'string') {
              console.error('workPictures should be a string');
              return [];
            }
    
            return workPictures.split(separator).filter(item => item);
          },
            amountBlur(name, ref) {
                this[name] = false;
                this.checkOurData[ref] = (parseFloat(this.checkOurData[ref])).toFixed(2);
                this.calculationRefundAmopunt();
                this.calculationpPayAmount();
            },
            amountClick(name, ref) {
                this[name] = true;
                this.$nextTick(() => this.$refs[ref].focus());
            },
            // 计算转移金额
            calculationRefundAmopunt() {
                let {transferRentalBalanceAmount, transferOtherBalanceAmount} = this.checkOurData;
                transferRentalBalanceAmount = parseFloat(transferRentalBalanceAmount);
                transferOtherBalanceAmount = parseFloat(transferOtherBalanceAmount);
                this.totalAmount = (transferRentalBalanceAmount +transferOtherBalanceAmount).toFixed(2);
                this.checkOurData.totalAmount = (transferRentalBalanceAmount +transferOtherBalanceAmount)*100;
            },
            // 计算剩余余额
            calculationpPayAmount() {
                let {depositBalanceTransferBeforeAmount,totalAmount} = this.checkOurData;
                console.log('depositBalanceTransferBeforeAmount',depositBalanceTransferBeforeAmount);
                this.checkOurData.depositBalanceTransferAfterAmount = (depositBalanceTransferBeforeAmount-totalAmount);
                this.depositBalanceTransferAfterAmount = parseFloat((this.checkOurData.depositBalanceTransferAfterAmount / 100).toFixed(2));
            },
            clickCancel() {
                // return
              // alert('取消')
                this.$emit('handleSearch');
                this.dialogVisible = false;
                this.checkOurData = {
                    leasorName:null,// 租户名称
                    transferDate:null, //申请时间
                    depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换前
                    transferRentalBalanceAmount:null,// 转移余额-房租余额 转换前
                    transferOtherBalanceAmount:null , // 转移杂费-房租余额 转换前
                    depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换前
                    totalAmount:null, // 转移余额总计 转换前
                    remark:null , // 备注
                };
                this.uuid = null;
            },
            numberFormat(number) {
                return numberFormat(Number(number) / 100);
            },
    
            // 保存押金工单
            saveWorkOrder() {
                let that = this;
                let leasorUuid = that.leasorUuid;
                const { leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount,
                        transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,totalAmount } = that.checkOurData
                this.transferRentalBalance = (transferRentalBalanceAmount * 100).toFixed(2);
                this.transferRentalBalanceAmount = parseFloat(this.transferRentalBalance);
                this.transferOtherBalance = (transferOtherBalanceAmount * 100).toFixed(2);
                this.transferOtherBalanceAmount = parseFloat(this.transferOtherBalance);
                let data = {
                        leasorUuid,
                        leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount:this.transferRentalBalanceAmount,
                        transferOtherBalanceAmount:this.transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,status:0,totalAmount
                }
                console.log('保存数据',data);
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                // //调用接口保存押金工单
                getSubmitTransferWorkOrder(data).then(res => {
                    console.log('res',res);
                    loading.close();
                    MessageSuccess('保存成功');
                    //调用取消方法，关闭弹窗
                    that.clickCancel();
                }).catch(() => loading.close());
            },
            // 提交押金工单
            clickSubmit() {
                let that = this;
                let leasorUuid = that.leasorUuid;
                const { leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount,
                        transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,totalAmount} = that.checkOurData
                this.transferRentalBalance = (transferRentalBalanceAmount * 100).toFixed(2);
                this.transferRentalBalanceAmount = parseFloat(this.transferRentalBalance);
                this.transferOtherBalance = (transferOtherBalanceAmount * 100).toFixed(2);
                this.transferOtherBalanceAmount = parseFloat(this.transferOtherBalance);
                let data = {
                        leasorUuid,
                        leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount:this.transferRentalBalanceAmount,
                        transferOtherBalanceAmount:this.transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,status:1,totalAmount
                }
                console.log('保存数据',data);
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                // //调用接口保存押金工单
                getSubmitTransferWorkOrder(data).then(res => {
                    console.log('res',res);
                    loading.close();
                    MessageSuccess('提交成功');
                    //调用取消方法，关闭弹窗
                    that.clickCancel();
                }).catch(() => loading.close());
            },
        },
    
        async created() {
            this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
            this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102 && item.code !== 500106);
        },
        watch: {
            'formPublish.isForce'(value) {
                if (!value) {
                    this.formPublish.depoBalanceRefund = 0;
                }
            }
        }
    }
    </script>
    
    <style scoped>
    .row {
        margin-bottom: 10px;
        padding: 10px 20px;
        border: 1px solid #D7D7D7;
    }
    
    .width300 {
        width: 300px;
    }
    .width200 {
        width: 200px;
    }
    .width100 {
        width: 100px;
    }
    .width250 {
        width: 250px;
    }
    .boder-right {
        border-right: 1px solid #D7D7D7;
    }
    
    .title {
        border-left: 5px #F5A623 solid;
        padding-left: 5px;
        margin: 15px 0;
        font-weight: 600;
    }
    
    .bg-E6E6E6 {
        background-color: #E6E6E6;
    }
    
    .bg-E2F5FF {
        background-color: #E2F5FF;
    }
    
    .bg-F8F8F8 {
        background-color: #F8F8F8;
    }
    
    .width33b {
        width: 50%;
    }
    
    .padding_0_30 {
        padding: 0 30px
    }
    
    .padding_10_30 {
        padding: 10px 30px;
    }
    
    .fw600 {
        font-weight: 600;
    }
    </style>